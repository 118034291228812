import { storeToRefs } from "pinia";
import { useUser } from "./useUser";
import { useEventStore } from "@/stores/modules/event";
import { usePurchaseStore } from "@/stores/modules/purchase";

import { useRoute } from "vue-router";
import { useWaitingRoom } from "./useWaitingRoom";

export const useEvent = () => {
  const eventStore = useEventStore();
  const purchaseStore = usePurchaseStore();
  const route = useRoute();
  const {
    details,
    events,
    media,
    channels,
    defaultChannels,
    tickets,
    isIframe,
    iframeStyles,
    eventsToArtist,
    eventsToArtistInCity,
    eventsToArtistInCountry,
    eventsToArtistPast,
    eventsToVenues,
    eventsRelatedToVenue,
    eventsRelatedToArtist,
    eventsRelatedToEvent,
    merchandising,
    configSeatsio,
    minTickets,
    available,
    pair,
    ticketOfficeAvailable,
    ticketOfficeReport
  } = storeToRefs(eventStore);
  const { isWhiteLabel, maxNumberTicketByCode } = storeToRefs(purchaseStore);
  const { userDetail } = useUser();

  const getEventsFollowed = async data => {
    let params = {
      page_size: 20,
      page: 1,
      followers: data.id
    };
    await eventStore.getEvents(params);
  };

  const getEventsRelatedToVenue = async (data, addAds = false) => {
    let params = {
      // related_to_venue: data.id,
      // TODO: Revisar y poner related
      // TODO: Crear por back el endpoint necesario. Solo existe api/events/?near_to_venue='' que es para salas relacionadas
      related_to_venue: data.id,
      page_size: data.page_size || 10,
      page: data.page || 1,
      count: true
    };
    await eventStore.getEventsRelatedToVenue(params);
    if (addAds && eventsRelatedToVenue.value.length > 1) {
      let cardAd = {
        add: {
          wegow_position: 2,
          wegow_environment: "prod",
          wegow_section: "events-related-to-value",
          country: 1,
          id: `wegow-ads-container-` + parseInt(Math.random() * 1000)
        },
        adType: "wegow-web-event-ad",
        adClass: "wegow-web-home-event-ad"
      };
      eventsRelatedToVenue.value.splice(1, 0, cardAd);
    }
  };
  const getEventsToVenueInfinite = async data => {
    let params = {
      venue: data.id,
      page_size: data.page_size || 10,
      page: data.page || 1,
      count: true
    };
    await eventStore.getEventsToVenueInfinite(params);
  };

  const deleteAdRelatedToVenue = id => {
    if (eventsRelatedToVenue.value.length > 0) {
      let indexDelete = eventsRelatedToVenue.value.findIndex(ad => {
        return ad.add?.id === id;
      });
      if (indexDelete !== -1) {
        eventsRelatedToVenue.value.splice(indexDelete, 1);
      }
    }
  };

  const getEventsRelatedToArtist = async (data, addAds = false) => {
    let params = {
      related_to_mag_artist: data.id,
      page_size: data.page_size || 10,
      count: true
    };
    await eventStore.getEventsRelatedToArtist(params);
    if (
      addAds &&
      (eventsRelatedToArtist.value.events.length > 1 ||
        eventsRelatedToArtist.value.events?.events?.length > 1)
    ) {
      let cardAd = {
        add: {
          wegow_position: 2,
          wegow_environment: "prod",
          wegow_section: "events-related-to-artist",
          country: 1,
          id: `wegow-ads-container-` + parseInt(Math.random() * 1000)
        },
        adType: "wegow-web-event-ad",
        adClass: "wegow-web-home-event-ad"
      };
      eventsRelatedToArtist.value.events.splice(1, 0, cardAd);
    }
  };
  const getEventsToArtistInfinite = async data => {
    let params = {
      artists: data.id,
      page_size: data.page_size || 10,
      page: data.page || 1,
      count: true
    };
    await eventStore.getEventsToArtistInfinite(params);
  };

  const deleteAdRelatedToArtist = id => {
    if (eventsRelatedToArtist.value.length > 0) {
      let indexDelete = eventsRelatedToArtist.value.findIndex(ad => {
        return ad.add?.id === id;
      });
      if (indexDelete !== -1) {
        eventsRelatedToArtist.value.splice(indexDelete, 1);
      }
    }
  };

  const getEventsRelatedToEvent = async (data, addAds = false) => {
    if (data.id) {
      let params = {
        related_to_mag_event: data.id,
        page: data.page,
        page_size: data.page_size || 10,
        count: true
      };
      await eventStore.getEventsRelatedToEvent(params);
      if (addAds && eventsRelatedToEvent.value.events.length > 1) {
        let cardAd = {
          add: {
            wegow_position: 2,
            wegow_environment: "prod",
            wegow_section: "events-related-to-event",
            country: 1,
            id: `wegow-ads-container-` + parseInt(Math.random() * 1000)
          },
          adType: "wegow-web-event-ad",
          adClass: "wegow-web-home-event-ad"
        };
        eventsRelatedToEvent.value.events.splice(1, 0, cardAd);
      }
    }
  };

  const deleteAdRelatedToEvent = id => {
    if (eventsRelatedToEvent.value.events?.length > 0) {
      let indexDelete = eventsRelatedToEvent.value.events.findIndex(ad => {
        return ad.add?.id === id;
      });
      if (indexDelete !== -1) {
        eventsRelatedToEvent.value.events.splice(indexDelete, 1);
      }
    }
  };

  const getEventsToArtist = async (artist, data = {}) => {
    let params = {
      artists: artist.id,
      page_size: data.page_size || 10,
      page: data.page || 1,
      count: true
    };
    await eventStore.getEventsToArtist(params);
  };

  const getCityArtistEvents = async (artist, location) => {
    let params = {
      artists: artist.id,
      hl_location: location.id
    };

    await eventStore.getCityArtistEvents(params);
  };
  const getCountryEvents = async (artist, location) => {
    let params = {
      country: location.country_id,
      artists: artist.id
    };
    await eventStore.getCountryEvents(params);
  };
  const getPreviousEvents = async (artist, location) => {
    let params = {
      hl_location: location.id,
      artists: artist.id,
      ordering: "-start_date",
      processing_status: 3
    };
    await eventStore.getPreviousEvents(params);
  };

  const getEventsToVenues = async data => {
    let params = {
      venue: data.id,
      page_size: data.page_size || 10,
      count: true
    };
    await eventStore.getEventsToVenues(params);
  };

  const getDetails = async slug => {
    maxNumberTicketByCode.value = -1;
    isIframe.value =
      route?.path.includes("/widget/") ||
      route?.path.includes("/waiting-room/");
    await eventStore.getDetails(slug, isWhiteLabel.value);
    setIframeStyles();
  };
  const setIframeStyles = () => {
    const hexRegex = /^([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
    iframeStyles.value = {
      ...details.value.iframe,
      ...Object.fromEntries(
        Object.entries(route.query).map(([key, value]) => [
          key,
          hexRegex.test(value) ? `#${value}` : value
        ])
      )
    };
  };

  const addToWaitingList = async (eventId, preferences = undefined) => {
    let payload = {
      eventId: eventId,
      ticket_types: preferences
    };
    await eventStore.addToWaitingList(payload);
  };

  const addToWaitingListNoLogin = async (eventId, userData = undefined) => {
    let payload = {
      eventId: eventId,
      ...userData
    };
    await eventStore.addToWaitingListNoLogin(payload);
  };

  const getTickets = async data => {
    let payload = {
      hb: isWhiteLabel.value === "" ? undefined : isWhiteLabel.value,
      event: details.value.id,
      is_streaming: false, // TODO: ¿La condicion?
      ...data
    };
    await eventStore.getTickets(payload);
  };

  const getSeatsioTickets = async seatsioPlaces => {
    let payload = {
      event: details.value.id,
      is_streaming: false, // TODO: ¿La condicion?
      places: seatsioPlaces
    };
    await eventStore.getSeatsioTickets(payload);
  };

  const getSeatsioConfig = async seatsioPlaces => {
    let payload = {
      ticket_dist: details.value.ticket_distributions[0].id,
      places: seatsioPlaces
    };
    await eventStore.getSeatsioConfig(payload);
  };

  const followEvent = async data => {
    const params = {
      event: data.id,
      tracking: true,
      going: false
    };
    data.followers_count += 1;
    data.user.following = true;
    userDetail.value.events_count += 1;
    await eventStore.followEvent(params);
  };

  const unfollowEvent = async data => {
    const params = {
      event: data.id,
      tracking: false,
      going: false
    };
    data.followers_count -= 1;
    data.user.following = false;
    userDetail.value.events_count -= 1;
    await eventStore.unfollowEvent(params);
  };

  const hasCulturalBonus = (event, timezone) => {
    if (
      (timezone === "Europe/Madrid" ||
        timezone === "Africa/Ceuta" ||
        timezone === "Atlantic/Canary") &&
      event.city?.iso_code === "ES" &&
      !event.has_options &&
      !event.hide_bonocultural
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getEventMedia = async ({ id }) => {
    await eventStore.getEventMedia({ event: id });
  };

  const getEventChannels = async event => {
    await eventStore.getEventChannels({ event: event.id });
  };

  const getTicketOfficeAvailable = async () => {
    await eventStore.getTicketOfficeAvailable();
  };

  const getTicketOfficeReport = async params => {
    let payload = {
      event: params.event,
      params: {
        date: params.params.from_date
          ? params.params.from_date + " " + params.params.from_hour
          : undefined,
        end_date: params.params.end_date
          ? params.params.end_date + " " + params.params.end_hour
          : undefined
      }
    };
    await eventStore.getTicketOfficeReport(payload);
  };
  const getMerchandising = async () => {
    await eventStore.getMerchandising();
  };

  return {
    //! Properties
    details,
    tickets,
    events,
    isIframe,
    iframeStyles,
    media,
    channels,
    defaultChannels,
    eventsToArtist,
    eventsToArtistInCity,
    eventsToArtistInCountry,
    eventsToArtistPast,
    eventsToVenues,
    eventsRelatedToVenue,
    eventsRelatedToArtist,
    eventsRelatedToEvent,
    merchandising,
    ticketOfficeAvailable,
    ticketOfficeReport,
    // Seatsio
    configSeatsio,
    minTickets,
    available,
    pair,
    //! Metodos
    getDetails,
    addToWaitingList,
    addToWaitingListNoLogin,
    getTickets,
    followEvent,
    unfollowEvent,
    getEventMedia,
    getEventChannels,
    getEventsFollowed,
    getEventsToArtist,
    getCityArtistEvents,
    getCountryEvents,
    getPreviousEvents,
    getEventsToVenues,
    getEventsRelatedToVenue,
    getEventsToVenueInfinite,
    getEventsToArtistInfinite,
    getEventsRelatedToArtist,
    getEventsRelatedToEvent,
    deleteAdRelatedToVenue,
    deleteAdRelatedToArtist,
    deleteAdRelatedToEvent,
    getTicketOfficeAvailable,
    getTicketOfficeReport,
    getMerchandising,
    // Seatsio
    getSeatsioConfig,
    getSeatsioTickets,
    setIframeStyles,
    hasCulturalBonus
  };
};
