import ChatApi from "@/api/ChatApi";
import { defineStore } from "pinia";

export const useChatStore = defineStore("chat", {
  state: () => ({
    ws: {},
    attempts: 0,
    chatsDetails: {
      privates: [],
      publics: []
    },
    messages: [],
    bubbleChatOpen: [],
    chats: {},
    lastMessageId: undefined,
    lastChatUpdated: undefined,
    chatsByEvent: [],
    chatsByCompany: [],
    chatUsers: [],
    canSendMessage: true,
    isChatVenue: false
  }),
  actions: {
    async getChatById({ id }) {
      this.isChatVenue = false;
      this.bubbleChatOpen = await ChatApi.getChat(id);
    },
    async getChatVenueId(id) {
      this.isChatVenue = true;
      this.bubbleChatOpen = id;
    },
    async getPublicChats() {
      this.chatsDetails.publics = (
        await ChatApi.getChats({
          private: false
        })
      ).chats;
    },
    async getPrivateChats() {
      this.chatsDetails.privates = (
        await ChatApi.getChats({
          private: true
        })
      ).chats;
    },
    async createChat({ title, type, members }) {
      this.chatsDetails.privates.push(
        await ChatApi.createChat({ title, type, members })
      );
    },
    async getChatsByCompany({ company }) {
      this.chatsByCompany = await ChatApi.getChats({ company });
    },
    async getChatsByEvent({ event }) {
      this.chatsByEvent = await ChatApi.getChats({ event });
    },
    async joinChat({ chat }) {
      await ChatApi.joinChat({ chat });
    },
    async removeChat({ chat }) {
      const chatFilter = ({ id }) => id !== chat;
      this.chatsDetails.privates = this.chatsDetails.privates.filter(
        chatFilter
      );
      this.chatsDetails.publics = this.chatsDetails.publics.filter(chatFilter);
      await ChatApi.removeChat({ chat });
    },
    async getChatMembers({ chat, page_size = undefined, page = undefined }) {
      this.chatUsers = await ChatApi.getChatMembers({ chat, page_size, page });
    },
    async getChatMessages({ chat }) {
      if (this.isChatVenue) {
        this.ws.addEventListener("message", event => {
          this.message.push(event.data);
        });
      } else {
        this.messages = (
          await ChatApi.getChatMessages({ chat })
        ).messages.reverse();
      }
    },
    async closeWS() {
      if (
        Object.keys(this.ws).length > 0 &&
        this.ws.readyState !== WebSocket.CLOSED
      )
        this.ws.close(1000, "end-chat");
    },
    async openWS(onopen, onmessage, onerror, onclose, auth_token) {
      const wsUrl = `${process.env.VUE_APP_SOCKET_NUXT_URL}?auth_token=${auth_token}`;
      this.ws = new WebSocket(wsUrl);
      this.ws.onopen = onopen;
      this.ws.onmessage = onmessage;
      this.ws.onerror = onerror;
      this.ws.onclose = onclose;
    },
    async openVenueWS(
      onopen,
      onmessage,
      onerror,
      onclose,
      auth_token,
      venue_id
    ) {
      let env = "";
      if (process.env.VUE_APP_ENV === "local") {
        env = "dev";
      } else {
        env = "prod";
      }
      const wsUrl = `wss://25u75hcjjh.execute-api.eu-west-1.amazonaws.com/${env}/?authToken=${auth_token}&chatType=venue&chatId=${venue_id}`;
      try {
        this.ws = new WebSocket(wsUrl);
        this.ws.onopen = onopen;
        this.ws.onmessage = onmessage;
        this.ws.onerror = onerror;
        this.ws.onclose = onclose;
      } catch (error) {
        console.log("Connection error:", error);
      }
    },
    addMessage(message) {
      this.messages.push(message);
    },
    async sendChatMessage({
      chat,
      clientref,
      created,
      message_type,
      text,
      type
    }) {
      if (this.canSendMessage) {
        this.canSendMessage = false;
        setTimeout(() => {
          this.canSendMessage = true;
        }, 500);

        this.ws.send(
          JSON.stringify({
            chat,
            clientref,
            created,
            message_type,
            text,
            type
          })
        );
      }
    },
    async sendChatVenueId(chat, message, action) {
      if (message) {
        this.ws.send(
          JSON.stringify({
            action,
            message,
            type: "venue",
            id: chat.toString()
          })
        );
      } else {
        this.ws.send(
          JSON.stringify({
            action,
            type: "venue",
            id: chat.toString()
          })
        );
      }
    }
  }
});
// async getChatById(params) {
//   await this.$axios
//     .get(`/chats/${params}/`)
//     .then(res => {
//       commit("openNewBubbleChat", res.data);
//     })
//     .catch(res => {
//       if (typeof window !== "undefined") {
//         if (typeof CustomEvent === "function") {
//           var event = new CustomEvent("showError", {
//             detail: {
//               textI18N: "error.title",
//               error: res
//             }
//           });
//           window.dispatchEvent(event);
//         }
//       }
//     });
// },
// async getPublicChats(params) {
//   await this.$axios
//     .get(`/chats/`, {
//       params: payload
//     })
//     .then(res => {
//       commit("setChatsListPublic", res.data);
//     })
//     .catch(res => {
//       if (typeof window !== "undefined") {
//         if (typeof CustomEvent === "function") {
//           var event = new CustomEvent("showError", {
//             detail: {
//               textI18N: "error.title",
//               error: res
//             }
//           });
//           window.dispatchEvent(event);
//         }
//       }
//     });
// },
// async getPrivateChats(params) {
//   await this.$axios
//     .get(`/chats/`, {
//       params: payload
//     })
//     .then(res => {
//       commit("setChatsListPrivate", res.data);
//     })
//     .catch(res => {
//       if (typeof window !== "undefined") {
//         if (typeof CustomEvent === "function") {
//           var event = new CustomEvent("showError", {
//             detail: {
//               textI18N: "error.title",
//               error: res
//             }
//           });
//           window.dispatchEvent(event);
//         }
//       }
//     });
// },
// async createChat(params) {
//   await this.$axios
//     .post(`/chats/`, payload)
//     .then(res => {
//       commit("addChatCreated", res.data);
//     })
//     .catch(res => {
//       if (typeof window !== "undefined") {
//         if (typeof CustomEvent === "function") {
//           var event = new CustomEvent("showError", {
//             detail: {
//               textI18N: "error.title",
//               error: res
//             }
//           });
//           window.dispatchEvent(event);
//         }
//       }
//     });
// },
// async removeChat(params) {
//   await this.$axios
//     .delete(`/chat-members/`, {
//       params: payload
//     })
//     .then(res => {
//       commit("removeChat", payload);
//     })
//     .catch(res => {
//       if (typeof window !== "undefined") {
//         if (typeof CustomEvent === "function") {
//           var event = new CustomEvent("showError", {
//             detail: {
//               textI18N: "error.title",
//               error: res
//             }
//           });
//           window.dispatchEvent(event);
//         }
//       }
//     });
// },
// async openNewBubbleChat(params) {
//   var isChatOpen = this.state.chat.bubbleChatOpen.indexOf(payload.id);
//   if (isChatOpen === -1) {
//     commit("addInNewBubbleChat", payload);
//   }
// },
// async closeBubbleChat(params) {
//   commit("removeBubbleChat", payload);
// },
// addNewMessage: async params => {
//   this.newMessage;
//   commit("addNewMessage", payload);
// },
// async getInitMessages(params) {
//   await apiService
//     .get(`/chat-messages/`, { params })
//     .then(res => {
//       var lastMessage =
//         res.data && res.data.messages && res.data.messages.length > 0
//           ? res.data.messages[0]
//           : false;
//
//       var messagesGroup = groupBy(res.data.messages, item => {
//         return item.chat;
//       });
//       var initChatMessages = mapValues(messagesGroup, item => {
//         return groupBy(item, messages => {
//           return `${new Date(
//             new Date(messages.created).getFullYear(),
//             new Date(messages.created).getMonth(),
//             new Date(messages.created).getDate()
//           ).getTime()}-A`;
//         });
//       });
//       if (lastMessage) {
//         this.lastMessageId = lastMessage.id;
//         this.lastChatUpdated = lastMessage.chat;
//       }
//       commit("addInitMessages", {
//         id: payload,
//         chat: initChatMessages
//       });
//       commit("changeChatStatus", {
//         id: payload,
//         status: "read"
//       });
//     })
//     .catch(res => {
//       if (typeof window !== "undefined") {
//         if (typeof CustomEvent === "function") {
//           var event = new CustomEvent("showError", {
//             detail: {
//               textI18N: "error.title",
//               error: res
//             }
//           });
//           window.dispatchEvent(event);
//         }
//       }
//     });
// },
// async getChatsByEvent(params) {
//   await apiService
//     .get(`/chats/`, { params })
//     .then(res => (this.chatsByEvent = res.data.chats))
//     .catch(res => {
//       if (typeof window !== "undefined") {
//         if (typeof CustomEvent === "function") {
//           var event = new CustomEvent("showError", {
//             detail: {
//               textI18N: "error.title",
//               error: res
//             }
//           });
//           window.dispatchEvent(event);
//         }
//       }
//     });
// },
// async getChatByCompany(params) {
//   await apiService
//     .get(`/chats/public/`, { params })
//     .then(res => (this.chatsByEvent = [res.data]))
//     .catch(res => {
//       if (typeof window !== "undefined") {
//         if (typeof CustomEvent === "function") {
//           var event = new CustomEvent("showError", {
//             detail: {
//               textI18N: "error.title",
//               error: res
//             }
//           });
//           window.dispatchEvent(event);
//         }
//       }
//     });
// }
