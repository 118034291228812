<template>
  <div class="ko-payment">
    <div class="ko-payment--container">
      <span class="ko-payment--container__title">
        {{ $t("myTicket.titles.ups") }}
      </span>
      <span class="ko-payment--container__subtitle">
        {{ $t("myTicket.titles.paymentFailed") }}
      </span>
      <wegow-button
        class="ko-payment--container__button"
        background="opacity"
        color="topaz"
        :text="$t('myTicket.titles.retryUpgrade')"
        @click="onRetryClick"
      />
      <span class="ko-payment--container__manage-other" @click="onManageOtherClick">
        {{ $t("myTicket.titles.continueManaging") }}
      </span>
    </div>
  </div>
</template>

<script setup>
import WegowButton from "@/components/general/forms/WegowButton.vue";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import { useTicketStore } from "@/stores/modules/ticket";

const router = useRouter();

const ticketStore = useTicketStore();

onMounted(async () => {
  const ticketCode = localStorage.getItem("ticket_code");
  const ticketEmail = localStorage.getItem("ticket_email");
  if (ticketCode && ticketEmail) {
    const payload = {
      email: ticketEmail,
      ticketCode: ticketCode,
    };
    await ticketStore.loginMyTicket(payload);
  }
  localStorage.removeItem("ticket_code");
  localStorage.removeItem("ticket_email");
  localStorage.removeItem("redsys_redirection");
});

const onRetryClick = () => {
  router.push({ name: "MyTicketUpgrade" });
};

const onManageOtherClick = () => {
  router.push({ name: "MyTicketLogin" });
};
</script>

<style lang="scss" scoped>
.ko-payment {
  display: flex;
  align-items: center;
  justify-content: center;
  &--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.625rem;
    margin-top: 6.75rem;
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 500px;
    width: 100%;
    &__title {
      font-weight: 700;
      font-size: 1.875rem;
    }
    &__subtitle {
      width: 18.75rem;
      text-align: center;
    }
    &__button {
      padding: 12px 55px !important;
      font-size: 18px !important;
      @media (max-width: 390px) {
        padding: 12px 55px !important;
        font-size: 18px !important;
      }
    }
    &__manage-other {
      cursor: pointer;
      text-decoration: underline;
      color: #2effec;
    }
  }
}
</style>
