export class ProductStockError extends Error {
  constructor(message) {
    super(message);
    this.name = "ProductStockError";
  }
}

export class CreatePurchaseError extends Error {
  constructor(message) {
    super(message);
    this.name = "CreatePurchaseError";
  }
}
