import apiService from "@/api/ApiService";

const PRODUCT_URL = "/crosssell/";
export class ProductsService {
  async getProductsByTicket(params) {
    const response = await apiService.getS3File(
      `${PRODUCT_URL}ticket-type/${params}.json`
    );
    return response.data;
  }
}

const productService = new ProductsService();

export default productService;
