<template>
  <div :style="getPersonalizedCheckbox">
    <div
      class="flex items-center"
      :class="{ 'justify-center': horizontalCenter }"
    >
      <!-- TODO:: comprobar w-auto -->
      <label
        class="text-base w-auto text-left flex items-baseline checked"
        :for="id + random"
        :class="{
          selected: value,
          error: error
        }"
      >
        <input
          :id="id + random"
          :name="id + random"
          :checked="value"
          :disabled="disabled"
          type="checkbox"
          :class="`mr-1 w-4 h-4 md:h-auto md:w-auto`"
          @input="emit('update:value', $event.target.checked)"
        />
        <div
          v-if="htmlTitle"
          class="checkbox-title select-none"
          :class="{
            [`${fontSize}`]: fontSize,
            'underline-links': underline
          }"
          v-html="title"
        />
        <div v-else class="checkbox-title select-none">
          {{ title }}
        </div>
      </label>
      <!-- <wegow-tooltip
        v-if="tooltipText || personalizedTooltip"
        class="ml-4"
        :show-tooltip="showTooltip"
        @change-tooltip="showTooltip = $event"
      /> -->
    </div>
    <span
      v-if="showTooltip"
      class="text-s block font-light text-grayDescription my-2"
    >
      <div v-if="personalizedTooltip">
        <slot name="tooltip"></slot>
      </div>
      <div v-else>{{ tooltipText }}</div>
    </span>
    <span class="text-s md:text-mm block font-light text-grayDescription">
      <slot name="subtitle">
        {{ subtitle }}
      </slot>
    </span>
    <!-- <wegow-error-message
      :class="{ hidden: !errorMessage }"
      :message="errorMessage ? $t(errorMessage) : '.'"
    /> -->
  </div>
</template>

<script setup>
import { onMounted, ref, toRefs } from "vue";
// import WegowTooltip from "@/components/ui/WegowTooltip";
// import WegowErrorMessage from "@/components/ui/WegowErrorMessage";
import { useIframeStyle } from "@/composables/useIframeStyle";

const {
  getPersonalizedCheckbox,
  getPersonalizedCheckboxBackground,
  getPersonalizedCheckboxChecked
} = useIframeStyle();

const props = defineProps({
  id: { type: String, required: true },
  value: { type: Boolean, required: true },
  title: { type: String, required: false, default: "" },
  htmlTitle: { type: Boolean, default: false },
  subtitle: { type: String, required: false, default: "" },
  fontSize: { type: String, required: false, default: "md" },
  // fontSize: xl-20 md-15 sm-11
  error: { type: Boolean, default: false },
  errorMessage: { type: String, required: false, default: "" },
  tooltipText: { type: String, required: false, default: "" },
  personalizedTooltip: { type: Boolean, required: false, default: false },
  horizontalCenter: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false },
  sizeWidth: { type: String, required: false, default: "20px" },
  sizeHeight: { type: String, required: false, default: "20px" },
  top: { type: String, required: false, default: "0" },
  underline: { type: Boolean, required: false, default: false },
  backgroundInput: {
    type: String,
    required: false,
    default: "#404256"
  }
});

const {
  id,
  title,
  value,
  subtitle,
  disabled,
  tooltipText,
  errorMessage,
  horizontalCenter,
  personalizedTooltip
} = toRefs(props);

const emit = defineEmits(["update:value"]);
const random = Math.trunc(Math.random() * 100);

const showTooltip = ref(false);
const backgroundCheckbox = ref(props.backgroundInput);

onMounted(() => {
  const styleElement = document.createElement("style");
  styleElement.type = "text/css";
  const beforeStylesString = Object.entries(
    getPersonalizedCheckboxBackground.value
  )
    .map(
      ([key, value]) =>
        `${key.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()}: ${value};`
    )
    .join(" ");
  styleElement.innerHTML = `
      .checked::before { ${beforeStylesString} }
    `;
  document.head.appendChild(styleElement);

  const styleElementChecked = document.createElement("style");
  styleElementChecked.type = "text/css";
  const beforeStylesStringChecked = Object.entries(
    getPersonalizedCheckboxChecked.value
  )
    .map(
      ([key, value]) =>
        `${key.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()}: ${value};`
    )
    .join(" ");
  styleElementChecked.innerHTML = `
      .checked.selected::before { ${beforeStylesStringChecked} }
    `;
  document.head.appendChild(styleElementChecked);
});
</script>

<style scoped lang="scss">
label {
  cursor: pointer;
}
.checkbox-title {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

input[type="checkbox"] {
  display: none; /* Ocultamos el checkbox por defecto */
}

label {
  position: relative;
  padding-left: 25px; /* Espacio para el cuadrado */
  cursor: pointer;
}

label::before {
  content: "";
  position: absolute;
  top: 7%;
  left: 0;
  width: v-bind(sizeWidth);
  height: v-bind(sizeHeight);
  border: 2px solid $inputLigthBackground; /* Color del cuadrado */
  background: $inputLigthBackground;
  height: 13px;
  width: 13px;
}
label.error::before {
  border: 1px solid $red;
}
label.selected::before {
  content: ""; /* Caracter del tick */
  background-image: url("@/assets/images/ic-checked-topaz.svg");
  background-size: contain;
  background-repeat: no-repeat;
  color: $topaz; /* Color del tick */
  font-size: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sm {
  font-size: 11px;
}
.md {
  font-size: 15px;
}
.xl {
  font-size: 20px;
}
</style>
