<template>
  <modal-template
    v-if="activeModal === SIGN_UP_PROMOTER"
    size="intermedium"
    :is-static="isStatic"
    :no-paddings="true"
    @close="canCloseModal ? closeModal() : ''"
  >
    <div id="containerSignupPromoter" class="container-onboarding">
      <div class="pt-12">
        <h3 class="mb-[14px]">{{ $t("wegow.login.signupPromoterTitle") }}</h3>
        <h4 class="mb-6">{{ $t("wegow.login.signupPromoterSubtitle") }}</h4>
        <div class="mx-2 md:mx-20 pb-[27px]">
          <div>
            <form class="space-y-1" @submit.prevent="signupEmail">
              <wegow-input
                id="email-signup"
                v-model:value="model.email"
                name="email"
                :placeholder="$t('wegow.login.email')"
                type="email"
                placeholder-color="#7F8295"
                :background-input="`bg-${backgroundInput}`"
              />
              <wegow-input
                id="email-confirmation-signup"
                v-model:value="model.emailConfirmation"
                name="confirmation"
                :placeholder="$t('wegow.login.confirmEmail')"
                placeholder-color="#7F8295"
                :background-input="`bg-${backgroundInput}`"
              />
              <!-- autocomplete="off"
                onpaste="return false"
                onCopy="return false"
                onCut="return false" -->
              <wegow-input
                id="password-signup"
                v-model:value="model.password"
                name="password"
                :placeholder="$t('wegow.login.password')"
                type="password"
                placeholder-color="#7F8295"
                :background-input="`bg-${backgroundInput}`"
              />
              <phone-selector
                :phone-selected="model.phone"
                :background-input="`bg-${backgroundInput}`"
                placeholder-color="#7F8295"
                @select-country="model.phone.country = $event"
                @change-phone="model.phone.phone = $event"
                @open-search="canCloseModal = !$event"
              />
              <cities-search
                id="location-selector-input"
                v-model:value="model.location"
                :placeholder="$t('wegow.profile.settings.location')"
                :background-color="`bg-${backgroundInput}`"
                @open-search="canCloseModal = !$event"
                @update-value="model.location = $event"
              />
              <div class="pt-[18px] custom-control custom-checkbox pb-[14px]">
                <wegow-checkbox
                  id="terms-accepted-checkbox"
                  v-model:value="model.terms_accepted"
                  :html-title="true"
                  :title="
                    $t('wegow.login.termsAndConditions', {
                      termsUrl: termsAndConditionsUrl,
                      PrivacityUrl: PrivacyPolicyUrl,
                    })
                  "
                  background-input="#000214B2"
                  size-width="13px"
                  size-height="13px"
                  top="4px"
                />
              </div>
              <div class="custom-control custom-checkbox pb-4">
                <wegow-checkbox
                  id="notifications-checkbox"
                  v-model:value="model.reciveNews"
                  :title="$t('wegow.login.reciveNews')"
                  background-input="#000214B2"
                  top="4px"
                  size-width="13px"
                  size-height="13px"
                />
              </div>
              <span>{{ errorMessage }}</span>
              <wegow-button
                button-id="confirm-signup-button"
                background="opacity"
                color="topaz"
                :text="$t('wegow.login.signupPromoter')"
                :disabled="
                  // invalid ||
                  isDisabled
                "
                type="submit"
                class="btn btn-signup"
              />
            </form>
          </div>
          <!-- <div class="or-title my-5">
            <span class="or-line">{{ $t("wegow.login.or") }}</span>
          </div>
          <div class="flex justify-center space-x-4">
            <img
              :src="require('@/assets/images/login-google.svg')"
              class="external-login-icon"
              alt="SignUp con Google"
            />
            <img
              :src="require('@/assets/images/login-fb.svg')"
              class="external-login-icon"
              alt="SignUp con Facebook"
            />
            <img
              :src="require('@/assets/images/login-spotify.svg')"
              class="external-login-icon"
              alt="SignUp con Spotify"
            />
            <img
              :src="require('@/assets/images/login-youtube.svg')"
              class="external-login-icon"
              alt="SignUp con Youtube"
            />
          </div> -->
        </div>
      </div>
    </div>
    <wegow-loader v-show="loading" class="load-more-loader" :middle="true" />
  </modal-template>
</template>

<script setup>
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import WegowLoader from "@/components/general/ui/WegowLoader";
import WegowButton from "@/components/general/forms/WegowButton";
import WegowInput from "@/components/general/forms/WegowInput";
import WegowCheckbox from "@/components/general/forms/WegowCheckbox";
import PhoneSelector from "@/components/general/forms/complex-forms/PhoneSelector";
import CitiesSearch from "@/components/general/forms/selectors/CitiesSearch.vue";

import { COUNTRIES } from "@/constants.js";
import { useUser } from "@/composables/useUser";
import { ref, computed, onMounted } from "vue";
import { useModalHandler } from "@/plugins/useModalHandler";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/modules/user";

const { SIGN_UP_PROMOTER, activeModal, closeModal } = useModalHandler();
const router = useRouter();
const { signUpPromoter, validatePromoter } = useUser();

const userStore = useUserStore();

const props = defineProps({
  isStatic: {
    type: Boolean,
    default: false,
  },
  origin: {
    type: String,
    default: "",
  },
  newPromoter: {
    type: Boolean,
    default: false,
  },
});
const errors = ref("");
const model = ref({
  email: "",
  emailConfirmation: "",
  password: "",
  phone: {
    country: {},
    phone: "",
  },
  location: {
    id: 0,
    name: "",
    place_id: "",
    longitude: 0,
    latitude: 0,
  },
  newsletter: false,
  terms_accepted: false,
});
const ubication = ref({});
const backgroundInput = ref("valhalla");
const fbSignupParams = ref({
  scope: "user_friends, email, public_profile, user_birthday, user_location, user_likes",
});
const loading = ref(false);
const signEnv = ref(process.env.VUE_APP_NUXT_ENV);
const canCloseModal = ref(true);
const errorMessage = ref(null);

const isDisabled = computed(() => {
  console.log("location", model.value.location);
  return (
    !model.value.terms_accepted ||
    !model.value.email ||
    !model.value.password ||
    !model.value.phone.phone ||
    model.value.phone.phone.length === 0 ||
    model.value.location.name === ""
  );
});
const termsAndConditionsUrl = computed(() => {
  return router.resolve({
    name: "TermsOfUsePage",
    params: { region: "es" },
  }).href;
});

const PrivacyPolicyUrl = computed(() => {
  return router.resolve({
    name: "PrivacyPolicyPage",
    params: { region: "es" },
  }).href;
});
onMounted(() => {
  // if (this.$gtm) {
  //   this.$gtm.push({
  //     event: "sign-up",
  //     placement: "pop-up",
  //     purchase: false,
  //     origin: this.origin
  //   });
  // }
  // let region = this.$route.params.region;
  let region = "es";
  model.value.phone.country = COUNTRIES.filter((country) => country.iso2 === region)[0];
  if (!model.value.phone.country) {
    model.value.phone.country = {
      name: "España",
      iso2: "es",
      dialCode: "34",
      priority: 0,
      areaCodes: null,
    };
  }
});
const modalTerms = (text) => {
  if (typeof CustomEvent === "function") {
    var event = new CustomEvent("showError", {
      detail: {
        text: text,
      },
    });
    window.dispatchEvent(event);
  }
};
const signupEmail = async () => {
  errorMessage.value = null;
  const isSignUp = await signUpPromoter(model.value);
  if (isSignUp) {
    await validatePromoter();
    // Redirigimos al nuevo BO
    // window.location.href = "/old-backoffice/events/create/";
    window.location.href = process.env.VUE_APP_BACKOFFICE;

    // let data = {
    //   email: model.value.email,
    //   password: model.value.password,
    //   newsletter: model.value.newsletter,
    //   terms_accepted: model.value.terms_accepted,
    //   sign_up: true,
    //   first_name: "",
    //   last_name: "",
    //   type: "email"
    // };
    // if (model.value.phone.phone && model.value.phone.phone.length > 0) {
    //   data.phone_number = model.value.phone.phone;
    //   data.phone_cou_code = model.value.phone.country.iso2;
    //   data.phone_prefix = model.value.phone.country.dialCode;
    // }
    // _signup(data);
  }
  else {
    errorMessage.value = userStore.registerPromoterError ? userStore.registerPromoterError : "Error ternario";
  }
};
const onSignupSuccess = (response) => {
  var data = {
    fb_token: response.authResponse.accessToken,
    type: "facebook",
    first_name: "",
    last_name: "",
    newsletter: model.value.newsletter,
    terms_accepted: model.value.terms_accepted,
    sign_up: true,
  };
  if (model.value.terms_accepted) _signup(data);
};
const _signup = async (data) => {
  // try {
  //   loading.value = true;
  //   await this.$store.dispatch("auth/sign", { data: data, purchase: false });
  //   if (this.newPromoter) {
  //     await this.$store.dispatch("auth/promoterValidation");
  //   }
  //   if (this.$store.state.auth.userIsAuthenticated) {
  //     await this.$store.dispatch("user/detail");
  //     await this.$store.dispatch("user/status");
  //     this.$gtm.push({ event: "sign_up", type: "direct" });
  //     this.$root.$emit("closeSignup");
  //     if (!this.newPromoter) {
  //       this.$root.$emit("openUserProfileOnboarding");
  //     }
  //     this.$gtag.set("userId", this.$store.state.user.detail.id);
  //     if (
  //       this.signEnv === "production" &&
  //       typeof window.google_trackConversion === "function"
  //     ) {
  //       window.google_trackConversion({
  //         google_conversion_id: 966208932,
  //         google_conversion_language: "en",
  //         google_conversion_format: "3",
  //         google_conversion_color: "ffffff",
  //         google_conversion_label: "N2nbCKys5XAQpNvczAM",
  //         google_remarketing_only: false
  //       });
  //     }
  //     if (this.newPromoter) {
  //       let payload = {
  //         user: this.$store.state.user.detail.id,
  //         data: {
  //           location: this.ubication
  //         }
  //       };
  //       await this.$store.dispatch("user/update", payload);
  //       if (this.$gtag && typeof this.$gtag.event === "function")
  //         this.$gtag.event("register_promoter", {
  //           event_category: "promoters_landing"
  //         });
  //       window.location.href = "/backoffice/events/create/";
  //     }
  //   }
  //   loading.value = false;
  // } catch (e) {
  //   loading.value = false;
  // }
};
</script>

<style scoped lang="scss">
#containerSignupPromoter {
  // background-image: url("@/assets/images/wegow-gradients.png");
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  background: linear-gradient(135deg, #145977 0%, #152658 35%, #972f4d 100%);
  h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
  }
  h4 {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }
  .or-title {
    position: relative;
    display: block;
    width: 100%;
    margin: 10px 0;
    text-align: center;
    font-size: 16px;
    z-index: 1;
    overflow: hidden;
    &:before,
    &:after {
      content: "\a0";
      position: absolute;
      top: 51%;
      width: 25%;
      height: 1px;
      background-color: $white;
      overflow: hidden;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}
</style>
