<template>
  <!-- Loaders -->
  <w-loader-complete v-if="showNormalPage" />

  <router-view v-if="showNormalPage"></router-view>

  <!-- Dialogs -->
  <sign-in v-if="showNormalPage" />
  <sign-up v-if="showNormalPage" />
  <sign-up-complete v-if="showNormalPage" />
  <sign-up-promoter v-if="showNormalPage" />
  <forget-password v-if="showNormalPage" />
  <change-password v-if="showNormalPage" />
  <unsubscribe-emails v-if="showNormalPage" />
  <buyer-modal v-if="showNormalPage" />
  <basic-info-modal v-if="showNormalPage" />
  <user-info v-if="showNormalPage" />
  <calendar-recommendation v-if="showNormalPage" />
  <perfect-companion-modal v-if="showNormalPage" />
  <tooltip-component v-if="showNormalPage" />
  <modal-error v-if="showNormalPage" />
  <moment-modal v-if="showNormalPage && activeModal === MOMENT" />
  <purchase-insurance-modal v-if="showNormalPage" v-bind="modalProps" />
  <moment-likes-modal v-if="showNormalPage" v-bind="modalProps" />
  <user-no-login-info-modal v-if="showNormalPage" />
  <modal-image-preview v-if="showNormalPage" v-bind="previewModalProps" />
  <modal-create-chat v-if="showNormalPage" />
  <modal-show-chat-users v-if="showNormalPage" v-bind="modalProps" />
  <modal-sync-artists v-if="showNormalPage" v-bind="modalProps" />
  <maintenance-page v-if="maintenancePage" />
  <server-error v-else-if="serverError" />
</template>

<script setup>
import ModalCreateChat from "./components/general/modals/ModalCreateChat.vue";
import MomentLikesModal from "@/components/general/modals/MomentLikesModal.vue";
import CalendarRecommendation from "@/components/general/modals/complex-modals/CalendarRecommendation";
import PerfectCompanionModal from "@/components/general/modals/complex-modals/PerfectCompanionModal";
import PurchaseInsuranceModal from "@/components/general/modals/complex-modals/PurchaseInsuranceModal";
import UserInfo from "@/components/general/modals/complex-modals/UserInfo.vue";
import WLoaderComplete from "@/components/general/ui/WLoaderComplete.vue";
import MomentModal from "@/components/wegow/moment/MomentModal";
import ModalError from "./components/general/modals/ModalError.vue";
import ModalImagePreview from "./components/general/modals/ModalImagePreview.vue";
import ModalShowChatUsers from "@/components/general/modals/ModalShowChatUsers.vue";
import BuyerModal from "./components/general/modals/complex-modals/BuyerModal.vue";
import BasicInfoModal from "./components/general/modals/complex-modals/BasicInfoModal.vue";
import ChangePassword from "./components/general/modals/complex-modals/ChangePassword.vue";
import UnsubscribeEmails from "./components/general/modals/complex-modals/UnsubscribeEmails.vue";
import ForgetPassword from "./components/general/modals/complex-modals/ForgetPassword.vue";
import SignIn from "./components/general/modals/complex-modals/SignIn.vue";
import SignUp from "./components/general/modals/complex-modals/SignUp.vue";
import SignUpComplete from "./components/general/modals/complex-modals/SignUpComplete.vue";
import SignUpPromoter from "./components/general/modals/complex-modals/SignUpPromoter.vue";
import TooltipComponent from "@/components/general/ui/elements/TooltipComponent.vue";
import MaintenancePage from "@/views/general/MaintenancePage.vue";
import ServerError from "@/views/general/ServerError.vue";
import UserNoLoginInfoModal from "./components/general/modals/complex-modals/UserNoLoginInfoModal.vue";

import { VueCookieNext } from "vue-cookie-next";

import { useRouter, useRoute } from "vue-router";

import { useUser } from "@/composables/useUser";
import { useCookie } from "@/composables/useCookies";
import { useModalHandler } from "@/plugins/useModalHandler";
import { computed, onBeforeMount, onMounted } from "vue";
import ModalSyncArtists from "@/components/general/modals/ModalSyncArtists.vue";

const { serverError, maintenancePage, showMaintenancePage } = useUser();

const router = useRouter();
const route = useRoute();

// Vue.use(LoadScript);
// Vue.use(FBSignInButton);
// Vue.use(GSignInButton);
// Vue.use(vue_fb, {
//   appId: `${process.env.FACEBOOK}`,
//   cookie: true,
//   xfbml: true,
//   version: "v4.0"
// });

const $cookie = VueCookieNext;
// TODO: revisar

const {
  activeModal,
  MOMENT,
  modalProps,
  previewModalProps
} = useModalHandler();

const showNormalPage = computed(
  () => !maintenancePage.value && !serverError.value
);

onBeforeMount(() => {
  fetch(window.location.href)
    .then(response => {
      const headers = response.headers;
      headers.forEach((value, name) => {
        if (name === "x-maintenance" && value) {
          showMaintenancePage();
        }
      });
    })
    .catch(error => {
      console.error(error);
    });
});

onMounted(async () => {
  await router.isReady();
  const hash = route.hash;
  const params = new URLSearchParams(hash.substring(1));

  if (hash.includes("a_aid")) {
    const a_aid = params.get("a_aid");
    localStorage.setItem("a_aid", a_aid);
  }
  if (hash.includes("a_bid")) {
    const a_bid = params.get("a_bid");
    localStorage.setItem("a_bid", a_bid);
  }
  loadQuantcastAndAffiliates();
});

const loadScript = src =>
  new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    script.defer = true;
    document.head.appendChild(script);
  });

const unloadScript = src =>
  new Promise((resolve, reject) => {
    const script = document.querySelector(`script[src="${src}"]`);
    if (!script) return;
    // reject(new Error(`Script "${src}" not found`));
    script.remove();
    return resolve();
  });

const loadQuantcastAndAffiliates = () => {
  const { acceptCookie, acceptCookieThirdParty } = useCookie();
  var allConsentsAccepted = function(tcData) {
    const purposeConsentsAccepted = Object.values(
        tcData.purpose.consents
      ).every(consent => !!consent),
      specialFeatureOptinsAccepted =
        !!tcData.specialFeatureOptins &&
        !!tcData.specialFeatureOptins[1] &&
        !!tcData.specialFeatureOptins[2];
    return purposeConsentsAccepted && specialFeatureOptinsAccepted;
  };
  var promises = [];
  var previousStatus;
  var firstLoad = true;
  if (window.__tcfapi) {
    window.__tcfapi("addEventListener", 2, async function(
      tcData,
      listenerSuccess
    ) {
      if (listenerSuccess) {
        if (
          (tcData.eventStatus === "useractioncomplete" ||
            tcData.eventStatus === "tcloaded") &&
          allConsentsAccepted(tcData)
        ) {
          if (typeof window.$nuxt !== "undefined") {
            // window.$nuxt.$gtag.optIn();
            if (firstLoad && previousStatus === "cmpuishown") {
              // window.$nuxt.$gtag.pageview({
              //   page_path: window.location.pathname,
              //   page_title: window.location.page_title,
              //   page_location: window.location.href
              // });
              firstLoad = false;
            }
          }
          // window.fbq("consent", "grant");
          acceptCookie({ accepted: true });
          acceptCookieThirdParty({ accepted: true });

          promises = [
            loadScript("https://static.criteo.net/js/ld/ld.js"),
            loadScript("/js/pap.js").catch(e => e),
            loadScript(
              "https://securepubads.g.doubleclick.net/tag/js/gpt.js"
            ).catch(e => e)
          ];

          await Promise.all(promises);
          // this.gapi.load('auth2', function() {
          //   this.gapi.auth2.init()
          //   this.gapi.auth2.getAuthInstance()
          // // //   "gapi.auth2 has been initialized with different options. Consider calling gapi.auth2.getAuthInstance() instead of gapi.auth2.init()."
          // // //   /* Ready. Make a call to gapi.auth2.init or some other API */
          // });
        } else {
          previousStatus = tcData.eventStatus;
          // if (typeof window.$nuxt !== "undefined") {
          //   window.$nuxt.$gtag.optOut();
          // }
          // window.fbq("consent", "revoke");
          acceptCookie({ accepted: false });
          acceptCookieThirdParty({ accepted: false });

          promises = [
            unloadScript("https://static.criteo.net/js/ld/ld.js"),
            unloadScript("/js/pap.js").catch(e => e),
            unloadScript(
              "https://securepubads.g.doubleclick.net/tag/js/gpt.js"
            ).catch(e => e)
          ];
          await Promise.all(promises);
        }
      }
    });
  }
};
</script>
