import {
  ProductStockError,
  CreatePurchaseError
} from "@/plugins/errors/purchase-error";
import i18n from "@/main";
import { useModalHandler } from "@/plugins/useModalHandler";

export const validateProductStock = (error = "") => {
  const { t } = i18n.global;
  if (
    error.status === 400 &&
    error.data.message === "Insuficient Stock in product"
  )
    throw new ProductStockError(t("wegow.purchase.errors.noStock"));
};

export const validateCreatePurchase = (error = "") => {
  const { openErrorModal } = useModalHandler();
  const { t } = i18n.global;
  if (
    error.status === 409 &&
    error.data.error === "Can't create negative amount of tickets"
  ) {
    openErrorModal({
      modal: {
        title: t("general.error"),
        description: t("wegow.purchase.errors.negativeAmountMessage"),
        btn_message: "wegow.error.accept"
      }
    });
    throw new CreatePurchaseError(t("wegow.purchase.errors.negativeAmount"));
  } else {
    openErrorModal({
      modal: {
        title: t("general.error"),
        description: t("wegow.purchase.errors.genericErrorMessage"),
        btn_message: "wegow.error.accept"
      }
    });
    throw new CreatePurchaseError(t("wegow.purchase.errors.genericError"));
  }
};
