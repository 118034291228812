<template>
  <fieldset
    class="container-search-location form-group"
    :class="{ searchIcon }"
  >
    <wegow-input
      id="city-search"
      ref="input"
      v-model:value="text"
      :placeholder="placeholder || $t('wegow.login.localization')"
      placeholder-color="#7F8295"
      :class="{ 'w-full': fullWidth, pL45: searchIcon, 'h-[33px]': height }"
      class="search-location"
      autocomplete="off"
      name="location"
      type="text"
      :background-input="backgroundColor ? backgroundColor : 'bg-grayInput'"
      :border-input="borderInput"
      text-color="text-color-white"
      :style="{ ...getInputStyle, ...inputStyle }"
      @focus="onFocus"
      @focusout="onFocusOut"
      @update:value="debounceInput($event)"
      @keydown="handleMenuKeydown($event)"
    />
    <div
      v-if="cities?.length > 0 && openCities && !loadingCities"
      class="relative"
    >
      <ul
        v-on-clickaway="clickAway"
        class="list-search z-10 shadow-md rounded-md shadow-black absolute bg-grayish-blue overflow-hidden left-0 right-0"
      >
        <li
          v-for="(city, index) in cities"
          :key="index"
          :class="{ 'bg-grayInput': index === currentIndex }"
          class="my-1 p-1 text-left"
        >
          <button
            :id="`select_city_index__${index}`"
            class="text-left"
            @touchstart="
              selectCity(city, $event);
              openCities = false;
            "
            @click="
              selectCity(city, $event);
              openCities = false;
            "
            @mousedown="
              selectCity(city, $event);
              openCities = false;
            "
          >
            <span>{{ city.name }}, </span>
            <small v-if="city.administrative_division" class="text-color-gray">
              {{ city.administrative_division }},
            </small>
            <small class="text-color-gray">{{ city.country.name }} </small>
          </button>
        </li>
      </ul>
    </div>
    <div v-else-if="openCities && loadingCities" class="list-search loading">
      <loader />
    </div>
  </fieldset>
</template>

<script setup>
import WegowInput from "@/components/general/forms/WegowInput";
import { useGeneral } from "@/composables/useGeneral";
import { useIframeStyle } from "@/composables/useIframeStyle";
import { onBeforeMount, ref, toRefs } from "vue";

const { cities, getCities } = useGeneral();
const { getInputStyle } = useIframeStyle();

const emit = defineEmits(["update:value", "change"]);
const props = defineProps({
  value: { type: Object, default: () => {} },
  placeholder: { type: String, default: null },
  volatile: { type: Boolean, required: false, default: false },
  fullWidth: { type: Boolean, required: false, default: false },
  searchIcon: { type: Boolean, required: false, default: false },
  backgroundColor: { type: String, default: "" },
  borderInput: { type: String, default: "border-grayInputBorder" },
  height: { type: String, default: "" },
  inputStyle: {
    type: Object,
    default: () => {
      return {};
    }
  }
});
const { volatile, value } = toRefs(props);

const debounce = ref(undefined);
const openCities = ref(false);
const loadingCities = ref(false);
const currentIndex = ref(-1);
const text = ref("");

const setTextValue = city => {
  if (volatile.value || !city?.place_id) text.value = undefined;
  else {
    text.value = city.name;
    if (city.administrative_division)
      text.value += `, ${city.administrative_division}`;
    if (city?.country) text.value += `, ${city.country?.name || city.country}`;
  }
};

const onFocus = () => (text.value = undefined);

const onFocusOut = event => {
  if (event.relatedTarget?.id.includes("select_city_index__")) return;
  openCities.value = false;
  currentIndex.value = -1;
  cities.value = [];
  setTextValue(value.value);
};

const handleMenuKeydown = event => {
  if (!["ArrowUp", "ArrowDown", "Enter"].includes(event.key)) return;
  event.preventDefault();
  const index = currentIndex.value;

  if (event.key === "ArrowUp") {
    if (index > 0) currentIndex.value--;
    else if (index <= 0) currentIndex.value = cities.value.length - 1;
  } else if (event.key === "ArrowDown") {
    if (index < cities.value.length - 1) currentIndex.value++;
    else if (index === cities.value.length - 1) currentIndex.value = 0;
  } else if (event.key === "Enter" && index > -1)
    selectCity(cities.value[index]);
};

const clickAway = () => {
  if (openCities.value) {
    openCities.value = false;
    currentIndex.value = -1;
    cities.value = [];
  }
};

const debounceInput = search => {
  if (!search) openCities.value = false;
  else {
    openCities.value = true;
    if (debounce.value) clearTimeout(debounce.value);
    debounce.value = setTimeout(async () => {
      await getCities(search.toLowerCase());
    }, 200);
  }
};

const selectCity = (city, event) => {
  event?.preventDefault();
  currentIndex.value = -1;
  openCities.value = false;
  setTextValue(city);
  value.value = city;
  emit("change", { old: value.value, new: city });
  emit("update:value", city);
  event?.stopPropagation();
};

onBeforeMount(() => setTextValue(value.value));
</script>
<style lang="scss">
#wegow-default-styles {
  .w-full {
    width: 100%;
  }

  .container-search-location {
    position: relative;

    .list-search {
      position: absolute;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      border: 1px solid $grismedio;
      border-top: none;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background-color: $grisdetallesopacitymax;

      li {
        padding: 5px 10px;
        border-bottom: 1px solid $grismedio;
        cursor: pointer;

        &:last-child {
          border: none;
        }
      }

      &.loading {
        overflow-y: hidden;
        height: 100px;

        .loader-box {
          height: 100px;
        }
      }
    }
  }

  .searchIcon:before {
    content: " ";
    position: absolute;
    top: 13px;
    left: 12px;
    color: white;
    width: 20px;
    height: 20px;
    background-size: 16px;
    background-repeat: no-repeat;
  }
}
</style>
