import { defineStore } from "pinia";
import ProductApi from "@/api/ProductApi";
import { usePurchase } from "@/composables/usePurchase";

export const useProductStore = defineStore("product", {
  state: () => {
    return {
      product: {},
      products: [],
      productsTicketRequire: [],
      productsRequired: [],
      productsTicketNoRequire: [],
      productsPurchase: [],
      productDeletedId: {},
      formattedTickets: [],
      error: ""
    };
  },
  getters: {
    totalProductPrice: state => {
      return state.productsPurchase.reduce(
        (total, { price }) => total + price || 0,
        0
      );
    },
    totalProductTaxes: state => {
      return state.productsPurchase.reduce(
        (total, { commision }) => total + commision || 0,
        0
      );
    },
    // TODO: refactor
    groupedProductsByGroup: state => {
      return state.products.reduce((acc, singleProduct) => {
        // Comprobamos que el producto esté enabled
        if (!singleProduct.enabled) return acc;
        // Se coge el grupo del producto
        const group = singleProduct.product.group;
        // Se comprueba que si no tiene grupo se añada el producto al acumulador(acc)
        if (!group) {
          acc.push(singleProduct);
          return acc;
        }
        // Si tiene grupo, buscamos en el acumulador si el id de ese grupo existe ya
        let groupObj = acc.find(item => item.id === group);
        if (groupObj) {
          // Se comprueba que esa talla no esté incluida en el grupo de objetos. Si no la tiene la añade
          if (
            singleProduct.product.size &&
            !groupObj.sizes.includes(singleProduct.product.size)
          ) {
            groupObj.sizes.push(singleProduct.product.size);
          }
          groupObj.products.push(singleProduct);
        } else {
          // Si no existe ese grupo creado, se genera.
          groupObj = {
            id: group,
            name: singleProduct.product.group_name,
            is_group: true,
            enabled: true,
            products: [singleProduct],
            sizes: [singleProduct.product.size],
            colors: [],
            ticket_type: singleProduct.tickettype || singleProduct.ticket_type,
            product: {
              name: singleProduct.product.name,
              price: singleProduct.product.price,
              ticket_required: singleProduct.product.ticket_required
            }
          };
          acc.push(groupObj);
        }
        const size = singleProduct.product.size;
        const color = singleProduct.product.color;

        if (size) {
          let sizeObj = groupObj.colors.find(
            productValue => productValue.size === size
          );

          if (!sizeObj) {
            sizeObj = {
              size: size,
              colors: []
            };
            groupObj.colors.push(sizeObj);
          }

          if (color && !sizeObj.colors.includes(color)) {
            sizeObj.colors.push(color);
          }
        }
        return acc;
      }, []);
    },
    purchaseProductsResume: state => {
      let productExists = {};
      const repeatProducts = state.productsPurchase.reduce((acc, product) => {
        if (acc.length > 0) {
          productExists = acc.find(prod => prod.id === product.id);
        }
        if (productExists?.quantity && !productExists.ticket_required) {
          productExists.quantity += product.quantity;
          productExists.price = product.price + productExists.price;
        } else {
          acc.push({ ...product });
        }
        return acc;
      }, []);
      return repeatProducts;
    }
  },
  actions: {
    async getProductsByTicket(params) {
      try {
        this.products = await ProductApi.getProductsByTicket(params);
        this.error = "";
      } catch (error) {
        this.error = error.message;
      }
    },
    clasifyProductsByTicketRequire() {
      for (const productIterator of this.groupedProductsByGroup) {
        if (productIterator.product.ticket_required) {
          this.productsTicketRequire.push(productIterator);
        } else if (productIterator.product.product_required) {
          this.productsRequired.push(productIterator);
        } else if (!productIterator.product.ticket_required) {
          this.productsTicketNoRequire.push(productIterator);
        }
      }
    },
    // TODO: Añadir productos asociados
    clasifyComplementaryItems() {
      const { purchaseData } = usePurchase();
      const items =
        purchaseData.value.complementary_items?.items?.independent_products;

      // Objeto temporal para agrupar los productos por id
      const itemMap = {};

      if (items) {
        // Recorremos los items y los agrupamos por id
        items.forEach(item => {
          if (itemMap[item.id]) {
            // Si el id ya existe en el objeto temporal, sumamos la cantidad
            itemMap[item.id].quantity += item.quantity;
            itemMap[item.id].price += item.price;
          } else {
            // Si no existe, añadimos el item al objeto temporal
            itemMap[item.id] = item;
          }
        });
        // Convertir el objeto temporal de vuelta a un array
        purchaseData.value.complementary_items.items.independent_products = Object.values(
          itemMap
        );
      }
    },
    resetProductsList() {
      this.productsTicketRequire = [];
      this.productsTicketNoRequire = [];
    },
    // Product:TODO: Revisar
    groupProductsByTicketTypes() {
      const productsTicketsId = {};

      for (const product of this.productsTicketRequire) {
        const tickettype = product.tickettype || product.ticket_type;
        if (!productsTicketsId[tickettype]) {
          productsTicketsId[tickettype] = [];
        }
        productsTicketsId[tickettype].push(product);
      }
      return productsTicketsId;
    },
    addProductsToTickets() {
      const { purchaseData } = usePurchase();
      const ticketsCopy = [...purchaseData.value?.tickets];
      const productsTicketsId = this.groupProductsByTicketTypes();

      // Filtrar solo tickets que tengan productos asignados
      const ticketsWithProducts = ticketsCopy
        .map(ticket => {
          if (productsTicketsId[ticket.ticket_type]) {
            return {
              ...ticket,
              products: [productsTicketsId[ticket.ticket_type]]
            };
          }
          return null;
        })
        .filter(ticket => ticket !== null);
      this.formattedTickets = ticketsWithProducts;
    },
    // Purchase Product
    setProductsPurchase(product) {
      this.productsPurchase.push(product);
    },
    deleteProductPurchase(product, ticket) {
      this.productDeletedId = { product, ticket };
      const productIndex = this.productsPurchase.findIndex(
        prod => prod.id === product && prod.ticket_id === ticket
      );
      if (productIndex >= 0) {
        this.productsPurchase.splice(productIndex, 1);
      }
    },
    createProductPurchaseData(product) {
      const {
        id,
        quantity,
        price,
        name,
        description,
        additional_data,
        size,
        color,
        ticket_id
      } = product;

      return {
        id,
        quantity,
        price,
        name,
        description,
        additional_data,
        size,
        color,
        ticket_id
      };
    }
  }
});
