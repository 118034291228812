<template>
  <div :id="id" class="wegow-input">
    <label
      v-if="label"
      :id="`${id}__wegow-input_label_`"
      class="flex label mb-2"
      :class="{
        'text-m': smallLabel,
        'text-lg': !smallLabel,
        'justify-center': centerLabel
      }"
      :for="id + labelFor"
    >
      {{ label }}
    </label>
    <span
      class="w-full flex relative flex-col"
      :class="{ 'h-full': !isContactForm }"
    >
      <input
        :id="id + labelFor"
        ref="input"
        class="w-full h-full border input-text"
        autocomplete="off"
        :class="{
          [`placeholder:${placeholderColor}`]: placeholderColor,
          [backgroundInput]: backgroundInput,
          [`${borderInput}`]: borderInput,
          [`${textColor}`]: textColor,
          [`${padding}`]: padding,
          'cursor-not-allowed': notAllowed,
          'border-orange-errors': hasError,
          'focus-class': focusClass,
          'big-input': bigInput,
          rounded: borderRadius === 'all',
          'rounded-t': borderRadius === 'medium',
          'max-h-[37px]': hasError
        }"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :max="max"
        :min="min"
        :step="step"
        :maxlength="maxLength"
        :style="{ ...getInputStyle, ...inputStyle }"
        :type="inputType"
        :autocapitalize="type === 'email' ? 'off' : ''"
        :mask="
          mask
            ? [
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/
              ]
            : ''
        "
        @input="handleInput"
        @change="validate($event.target.value) & emit('change', $event)"
        @focus="emit('focus')"
        @focusout="emit('focusout', $event)"
        @keydown.enter="emit('enter', $event)"
      />
      <div v-if="type === 'password'">
        <img
          class="input-icon cursor-pointer"
          src="https://cdn.wegow.com/no-delete/wegow/show.svg"
          alt="show-password"
          @click="
            input.type === 'password'
              ? (input.type = 'text')
              : (input.type = 'password')
          "
        />
      </div>
      <div
        v-if="icon"
        class="input-icon cursor-pointer"
        :class="borderIcon ? 'border-icon' : ''"
        @click="emit('clickIcon')"
      >
        <img
          v-if="
            icon.includes('/assets/images/') ||
              icon.includes('/img/') ||
              icon.includes('https://')
          "
          width="28"
          height="20"
          :src="icon"
          :alt="iconAlt"
          class="input icon"
        />
        <i v-else :class="icon" />
      </div>
      <wegow-error-message
        v-if="hasError"
        :message="$t(errorMessage)"
        justify-message="justify-start"
      />
    </span>
    <span
      :id="`${id}__wegow-input_description_`"
      class="text-s md:text-mm block font-light mt-1 text-grayDescription"
      :class="{ hidden: !description }"
      tabindex="-1"
    >
      <slot name="description">
        {{ description }}
      </slot>
    </span>
  </div>
</template>

<script setup>
import { defineExpose, onMounted, ref, toRefs, watch } from "vue";
import { useIframeStyle } from "@/composables/useIframeStyle";
import WegowErrorMessage from "@/components/general/forms/WegowErrorMessage";

const { getInputStyle } = useIframeStyle();

const props = defineProps({
  id: {
    required: true,
    type: String,
    default: ""
  },
  maxLength: {
    type: String,
    default: ""
  },
  label: {
    type: String,
    default: ""
  },
  labelFor: {
    type: String,
    default: "input"
  },
  description: {
    type: String,
    default: ""
  },
  padding: {
    type: String,
    default: "px-4 py-2"
  },
  placeholder: {
    type: String,
    default: ""
  },
  step: {
    type: String,
    default: ""
  },
  type: {
    type: String,
    default: "text"
  },
  disabled: {
    type: Boolean,
    default: false
  },
  mask: {
    type: Boolean,
    default: false
  },
  value: {
    type: [String, Number],
    default: "",
    required: false
  },
  max: {
    type: [String, Number],
    default: "",
    required: false
  },
  min: {
    type: [String, Number],
    default: "",
    required: false
  },
  errorMessage: {
    type: String,
    required: false,
    default: ""
  },
  showError: {
    type: Boolean,
    required: false,
    default: false
  },
  showErrorAlways: {
    type: Boolean,
    required: false,
    default: false
  },
  notAllowed: {
    type: Boolean,
    default: false
  },
  smallLabel: {
    type: Boolean,
    default: false
  },
  centerLabel: {
    type: Boolean,
    default: false
  },
  tooltipText: {
    type: String,
    required: false,
    default: ""
  },
  personalizedTooltip: {
    type: Boolean,
    required: false,
    default: false
  },
  backgroundInput: {
    type: String,
    default: "bg-grayInput"
  },
  borderInput: {
    type: String,
    default: "border-grayInputBorder"
  },
  textColor: {
    type: String,
    default: ""
  },
  borderRadius: {
    type: String,
    default: "all"
  },
  placeholderColor: {
    type: String,
    default: ""
  },
  focusClass: {
    type: Boolean,
    default: false
  },
  bigInput: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: ""
  },
  iconAlt: {
    type: String,
    default: ""
  },
  borderIcon: {
    type: Boolean,
    default: false
  },
  focused: {
    type: Boolean,
    default: false
  },
  validator: {
    type: Object,
    required: false,
    default: undefined
  },
  inputStyle: {
    type: Object,
    required: false,
    default: () => {
      return {};
    }
  },
  isContactForm: {
    type: Boolean,
    default: false
  }
});

const { focused, validator, value, showErrorAlways, showError } = toRefs(props);
const valid = ref(true);
const showTooltip = ref(false);
const input = ref(null);
const inputType = ref(props.type);
const placeholderColorType = ref(props.placeholderColor);
const hasError = ref(false);

const focus = () => input.value.select();

const validate = value =>
  validator.value
    ? emit("valid", (valid.value = validator.value.validate(value)))
    : true;

const validateChange = value =>
  validator.value ? emit("validChange", validator.value.validate(value)) : true;

onMounted(() => focused.value && focus());
defineExpose({ focus, input });

watch(showErrorAlways, (newValue, oldValue) => {
  if (newValue && validator.value) {
    validate(value.value);
  }
});

watch(showError, (newValue, oldValue) => {
  if (newValue) {
    hasError.value =
      (value.value || props.showErrorAlways) &&
      (props.showError || !valid.value) &&
      props.errorMessage;
  }
});

const emit = defineEmits([
  "update:value",
  "input",
  "change",
  "focus",
  "focusout",
  "clickIcon",
  "valid",
  "validChange",
  "enter"
]);
const handleInput = event => {
  const newValue = event.target.value;
  emit("input", newValue);
  emit("update:value", newValue);
  validateChange(newValue);
};
</script>

<style scoped lang="scss">
.input-text {
  @media screen and (max-width: 700px) {
    font-size: 0.8rem;
  }
}
input.focus-class:focus {
  @apply border-orange-errors;
  box-shadow: 0px 0px 10px 1px black !important;
}

.border-orange-errors {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 85 85 / var(--tw-border-opacity)) !important;
}

.border-transparent:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.big-input {
  @apply text-m font-museo-500;
  padding: 20px 35px;
}
.input-icon {
  position: absolute;
  width: 28px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
  left: unset;
  right: 12px;
  padding-left: 8px;
  display: flex;
}
.border-icon {
  border-left: 1px solid #ffffff;
}
input::placeholder {
  font-weight: 300;
  font-size: 14px;
  line-height: 100px;
  color: v-bind(placeholderColorType);
}
input:-webkit-autofill {
  background-color: #404256 !important;
}
</style>
