<template>
  <button
    :id="buttonId"
    :ref="`btn-${buttonId}`"
    class="wegow-button overflow-hidden truncate"
    :class="{
      'cursor-not-allowed': disabled || loading,
      transparent: background === 'transparent' && color === '',
      'opacity-grey': color === 'grey' && background === 'opacity',
      'opacity-topaz': color === 'topaz' && background === 'opacity',
      'opacity-topaz-light':
        color === 'topaz-light' && background === 'opacity',
      'transparent-topaz':
        color === 'topaz' && background === 'transparent' && border != 'topaz',
      'border-topaz-color-white':
        color === 'white' && background === 'transparent' && border === 'topaz',
      'opacity-red': color === 'red' && background === 'opacity',
      'transparent-red': color === 'red' && background === 'transparent',
      'transparent-white':
        color === 'white' && background === 'transparent' && border != 'topaz',
      'button-xxs': size === 'xxs',
      'button-xs': size === 'xs',
      'button-sm': size === 'sm',
      'button-md': size === 'md',
      'button-base': size === 'base',
      'button-xl': size === 'xl',
      'button-xxl': size === 'xxl',
      'fontsize-xs': fontSize !== '' && fontSize === 'xs',
      'fontsize-base': fontSize !== '' && fontSize === 'base',
      'fontsize-xl': fontSize !== '' && fontSize === 'xl',
      'external-btn': size === 'external-btn',
      'banner-button-xl': size === 'banner-xl',
      'banner-button-xxl': size === 'banner-xxl',
      'follow-profile-btn': size === 'follow-profile-btn',
      'button-square': shape === 'square',
      'flex justify-around': textType === 'multiple',
      'border-bold': border === '2',
      'custom-font-size': customFontSize !== '',
      'custom-padding': customPadding !== '',
      'sub-text': subText !== ''
    }"
    :type="type"
    :disabled="disabled"
    :aria-label="$t('wegow.arias.components.btnLabel', { text })"
    :style="styles"
    @click="!disabled || loading ? $emit('click') : ''"
  >
    <span
      v-if="!loading"
      class="flex h-full items-center"
      :class="{
        'justify-center': textType === 'img-multiple' || subText !== '',
        'justify-evenly': textType !== 'img-multiple' && subText === '',
        'flex-col': subText !== ''
      }"
      @click="emitClick"
    >
      <span v-if="textType === 'img-multiple'">
        <img
          :src="icon"
          alt="mixed icon"
          class="mr-2"
          :class="{
            'image-xl': size === 'xl' || imageSize === 'xl',
            'image-base': imageSize === 'base',
            'image-small': imageSize === 'small'
          }"
        />
      </span>
      <div
        v-if="
          textType === 'text' ||
            textType === 'multiple-icon' ||
            textType === 'multiple-img' ||
            textType === 'img-multiple'
        "
        class="text-area"
        :class="[
          textColor !== '' ? textColor : color,
          { 'text-[20px]': subText !== '' }
        ]"
      >
        {{ text }}
      </div>
      <span v-if="subText !== ''" class="sub-title mt-1">
        {{ subText }}
      </span>
      <span v-if="textType === 'img' || textType === 'multiple-img'">
        <img
          class="ml-3"
          :src="icon"
          alt="mixed icon"
          :class="{
            'image-xl': size === 'xl' || imageSize === 'xl',
            'image-base': imageSize === 'base',
            'image-small': imageSize === 'small'
          }"
        />
      </span>
    </span>
    <wegow-loader
      v-else
      :color="
        color === 'topaz' ||
        (color === 'topaz-light' && background === 'opacity')
          ? 'black'
          : ''
      "
      :height="5"
      size="small"
    />
    <slot />
  </button>
  <!-- <wegow-loader v-else /> -->
</template>

<script setup>
import WegowLoader from "@/components/general/ui/WegowLoader";
import WIcon from "@/components/general/ui/elements/WIcon.vue";
import { computed } from "vue";

const props = defineProps({
  buttonId: {
    required: true,
    type: String
  },
  text: {
    type: String,
    default: "Enviar"
  },
  subText: {
    type: String,
    default: ""
  },
  type: {
    type: String,
    default: "button"
  },
  // class will be apply transparent, opacity, none
  background: {
    type: String,
    default: "transparent"
  },
  color: {
    type: String,
    default: "white"
  },
  disabled: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: "base"
  },
  fontSize: {
    type: String,
    default: ""
  },
  imageSize: {
    type: String,
    default: ""
  },
  shape: {
    type: String,
    default: "circle"
  },
  textType: {
    type: String,
    default: "text"
  },
  icon: {
    type: String,
    default: ""
  },
  border: {
    type: String,
    default: ""
  },
  textColor: {
    type: String,
    default: ""
  },
  customFontSize: {
    type: String,
    default: ""
  },
  customPadding: {
    type: String,
    default: ""
  },
  styles: {
    type: [String, Object],
    default: ""
  },
  fontWeight: {
    type: String,
    default: "400"
  }
});
const emit = defineEmits(["click", "clickDisabled"]);

const emitClick = () => {
  if (props.disabled && !props.loading) {
    emit("clickDisabled");
  }
};
</script>

<style scoped lang="scss">
button.wegow-button {
  @apply font-museo-500;
  border-radius: 50px;
  border-style: solid;
  border-width: 1.5px;
  box-sizing: content-box;
  color: $black;
  cursor: pointer;
  font-weight: v-bind(fontWeight);
  outline: 0;
  text-align: center;
  user-select: none;
  padding-left: 30px;
  padding-right: 30px;
  @media screen and (max-width: $screen-md) {
    padding-left: 13px;
    padding-right: 13px;
  }

  // Size
  &.button-md {
    font-size: 14px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding: 4.5px;
    @media screen and (min-width: $screen-md) {
      font-size: 20px;
      padding-left: 43px !important;
      padding-right: 43px !important;
      padding: 5px;
    }
  }
  &.button-xxs {
    @apply py-0 px-4 text-s;
  }
  &.button-xs {
    @apply py-1 px-4 text-s;
  }
  &.button-sm:focus {
    color: v-bind(color);
  }
  &.button-base {
    @apply py-1.5 text-base;
    height: 24px;
  }
  &.button-square {
    border-radius: 5px !important;
  }
  &.button-xl {
    @apply py-2 text-m;
    height: 30px;
    @media screen and (max-width: $screen-md) {
      @apply text-s;
      height: 20px;
    }
  }
  &.button-xxl {
    @apply py-1.5;
    font-size: 1.6rem;
  }
  &.banner-button-xl {
    width: fit-content;
    font-size: 14px;
    padding: 4.5px 20px;
    @media screen and (min-width: $screen-lg) {
      font-size: 20px;
      padding: 10px 30px;
    }
  }
  &.banner-button-xxl {
    height: 1.6rem;
    font-size: 0.875rem;
    @media screen and (min-width: $screen-lg) {
      height: 3.6rem;
      font-size: 1.6rem;
    }
  }
  &.external-btn {
    padding: 6px 28px;
  }
  // End Size

  // Font
  &.fontsize-xs {
    font-size: 12px;
    @media screen and (min-width: $screen-md) {
      font-size: 14px;
    }
  }
  &.fontsize-base {
    font-size: 16px;
  }
  &.fontsize-xl {
    font-size: 25px;
  }

  &:active,
  &:focus,
  &:visited {
    outline: 0;
    user-select: none;
    box-shadow: none;
    color: $white;
  }

  &.transparent {
    background-color: transparent !important;
    border-color: transparent !important;
    color: $white;
  }

  &.opacity-topaz {
    background-color: $topaz;
    color: $backgroundweb !important;
    border: none;
    div {
      color: $backgroundweb !important;
    }
  }
  &.opacity-topaz-light {
    background-color: $topaz;
    color: $backgroundweb;
    border: none;
  }
  &.transparent-red {
    background-color: transparent;
    border-color: $red;
    color: $white;
    &.w-active {
      color: $red;
    }

    &:focus {
      box-shadow: 0px 0px 10px 1px $red !important;
    }
  }
  &.transparent-white {
    background-color: transparent;
    border-color: $white;
    color: $white;
  }
  &.border-topaz-color-white {
    background-color: transparent;
    border-color: $topaz;
    color: $white;
  }

  &.opacity-red {
    background-color: $red;
    color: $backgroundweb;
    border: none;

    // &:hover,
    &.w-active {
      color: $red;
      border: 1px solid $red !important;
      background-color: transparent;
    }

    &:focus {
      box-shadow: 0px 0px 10px 1px $red !important;
    }
  }
  &.opacity-grey {
    background-color: $grey;
    border: solid 1px transparent;
    color: $black;
    &:hover {
      background-color: $grey;
    }
  }

  &.transparent-topaz {
    background-color: transparent;
    border-color: $topaz;
    color: $white;
  }

  &[disabled] {
    opacity: 0.5;
    border-color: #7f8295;
    background-color: #7f8295;
    color: #000214;

    cursor: not-allowed;
    &:hover {
      border-color: #7f8295;
      background-color: #7f8295;
      color: #000214;

      cursor: not-allowed;
    }
  }
  &.cursor-not-allowed {
    cursor: not-allowed !important;
  }
  img {
    widows: 100%;
    height: 100%;
    aspect-ratio: 1/1;
  }
  .image-xl {
    height: 45px;
    width: 45px;
    margin-left: 0;
    margin-right: 10px;
  }
  .image-base {
    height: 30px;
    width: 30px;
  }
  .image-small {
    height: 1.3rem;
    width: 1.3rem;
    margin-right: 6px;
  }
  &.follow-profile-btn {
    height: 30px;
    padding-right: 28px;
    padding-left: 28px;
    @media screen and (min-width: $screen-lg) {
      height: 48px;
      font-size: 18px;
      padding-right: 39px;
      padding-left: 39px;
    }
  }
}
.topaz {
  color: $topaz;
}
.border-bold {
  border: 2px solid $black !important;
  &:active,
  &:focus,
  // &:hover,
  &:visited {
    color: $black !important;
  }
  .text-area {
    font-weight: 900;
  }
}
.custom-font-size {
  font-size: v-bind(customFontSize) !important;
  padding: 0.5rem 1.5rem;
  width: 327px;

  div {
    padding: 0 6px 0 0 !important;
  }
  img {
    width: 15px !important;
    height: 15px !important;
  }
}
.custom-padding {
  padding: v-bind(customPadding);
}
.sub-text {
  width: 199px;
  height: 62px !important;
  font-size: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  .sub-title {
    font-size: 12px;
    line-height: 1.6;
  }
}
</style>
