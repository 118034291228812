<template>
  <modal-template
    v-if="activeModal === CHANGE_PASSWORD"
    size="intermedium"
    :is-static="false"
    :cancel-outside-click="true"
    @close="closeModal"
  >
    <wegow-loader v-if="loading" class="load-more-loader" :middle="true" />
    <div v-else id="containerSignin">
      <div class="pt-4 md:pt-12 md:mx-16">
        <h3 class="mb-8">{{ $t("wegow.login.forgetPasswordButton") }}</h3>
        <div>
          <form @submit.prevent="changePassword(model.password, token)">
            <div class="space-y-4">
              <wegow-input
                id="password-signup"
                v-model:value="model.password"
                name="password"
                type="password"
                :placeholder="$t('wegow.login.newPassword')"
                :validator="validators.password"
                @valid="valid.password = $event"
                @input="valid.password = $event"
              />
              <wegow-input
                id="password-confirmation-signup"
                v-model:value="model.passwordConfirmation"
                name="password-confirmation"
                :error-message="errors?.confirmation"
                :placeholder="$t('wegow.login.confirmPassword')"
                type="password"
                :validator="validators.confirmation"
                @valid="valid.confirmation = $event"
                @input="valid.confirmation = $event"
              />
            </div>
            <!-- <div class="mt-6 mb-8">
              <ul>
                <li>{{ $t("wegow.login.passwordRequired1") }}</li>
                <li>{{ $t("wegow.login.passwordRequired2") }}</li>
                <li>{{ $t("wegow.login.passwordRequired3") }}</li>
                <li>{{ $t("wegow.login.passwordRequired4") }}</li>
              </ul>
            </div> -->
            <div class="text-center mt-6">
              <wegow-button
                background="opacity"
                color="topaz"
                type="submit"
                :disabled="isDisabled"
                :text="$t('wegow.login.changePasswordButton')"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </modal-template>
</template>

<script setup>
import { useUser } from "@/composables/useUser";

import WegowButton from "@/components/general/forms/WegowButton";
import WegowInput from "@/components/general/forms/WegowInput";
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import WegowLoader from "@/components/general/ui/WegowLoader";

import { useModalHandler } from "@/plugins/useModalHandler";
import { computed, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";

const { changePassword } = useUser();

const route = useRoute();

const { activeModal, CHANGE_PASSWORD, closeModal } = useModalHandler();

const loading = ref(false);
const token = ref("");

const modelTemplate = { password: "", passwordConfirmation: "" };
const model = reactive({ ...modelTemplate });
const valid = reactive({ password: false, confirmation: false });

const validators = reactive({
  password: { validate: password => /^.{8,}$/.test(password || "") },
  confirmation: {
    validate: confirmation => confirmation === model.password && valid.password
  }
});

const isDisabled = computed(() => Object.values(valid).includes(false));

watch(activeModal, async (newValue, _oldValue) => {
  if (newValue === CHANGE_PASSWORD) {
    token.value = route.query.token;
  }
});
watch(
  () => model.passwordConfirmation,
  newValue => {
    valid.confirmation = validators.confirmation.validate(newValue);
  }
);

watch(
  () => model.password,
  () => {
    valid.password = validators.password.validate(model.password);
    valid.confirmation = validators.confirmation.validate(
      model.passwordConfirmation
    );
  }
);
</script>
<style lang="scss" scoped>
#containerSignin {
  h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
  }
}
</style>
