import { storeToRefs } from "pinia";
import { useUser } from "./useUser";
import { useVenueStore } from "@/stores/modules/venue";

export const useVenue = () => {
  const venueStore = useVenueStore();
  const {
    venues,
    media,
    details,
    venuesRelatedToVenue,
    venuesRelatedToEvent
  } = storeToRefs(venueStore);
  const { userDetail } = useUser();

  const getVenues = async query => {
    let params = {
      page_size: 20,
      page: 1,
      query: query
    };
    await venueStore.getVenues(params);
  };

  const getVenuesFollowed = async data => {
    let params = {
      page_size: 20,
      page: 1,
      followers: data.id
    };
    await venueStore.getVenues(params);
  };

  const getVenuesRelatedToVenue = async data => {
    let params = {
      // near_to_venue: data.id,
      related_to_venue: data.id,
      page_size: data.page_size || 10
    };
    await venueStore.getVenuesRelatedToVenue(params);
  };

  const getVenuesRelatedToEvent = async data => {
    let params = {
      // near_to_venue: data.venue?.id,
      related_to_venue: data.venue?.id,
      page_size: data.page_size || 10
    };
    await venueStore.getVenuesRelatedToEvent(params);
  };

  const followVenue = async data => {
    const params = {
      venue: data.id
    };
    data.followers_count += 1;
    data.user.following = true;
    userDetail.value.artists_count += 1;
    await venueStore.followVenue(params);
  };

  const unfollowVenue = async data => {
    const params = {
      venue: data.id
    };
    data.followers_count -= 1;
    data.user.following = false;
    userDetail.value.artists_count -= 1;
    await venueStore.unfollowVenue(params);
  };

  const getDetails = async slug => await venueStore.getDetails(slug);
  const getVenueMedia = async ({ id }) =>
    await venueStore.getVenueMedia({ venue: id });
  return {
    //! Properties
    venues,
    media,
    details,
    venuesRelatedToVenue,
    venuesRelatedToEvent,
    //! Metodos
    getVenues,
    getDetails,
    followVenue,
    unfollowVenue,
    getVenueMedia,
    getVenuesFollowed,
    getVenuesRelatedToVenue,
    getVenuesRelatedToEvent
  };
};
